import React from 'react';

export interface FlexProps {
  children?: React.ReactNode;
  className?: string;
  alignItems?: 'start' | 'end' | 'center' | 'baseline' | 'stretch';
  justifyContent?:
    | 'normal'
    | 'start'
    | 'end'
    | 'center'
    | 'between'
    | 'around'
    | 'evenly'
    | 'stretch';
  mobileFlex?: boolean;
  flexDirection?: 'row' | 'row-reverse' | 'col' | 'col-reverse';
}

const Flex = (props: FlexProps) => {
  const {
    children,
    className,
    alignItems,
    justifyContent,
    mobileFlex = true,
    flexDirection = 'row',
  } = props;

  const getFlexResponsive = () => (mobileFlex ? 'd-flex' : 'd-md-flex');

  const classNames: string[] = [getFlexResponsive()];

  if (className) {
    classNames.push(className);
  }

  if (flexDirection) {
    classNames.push('flex-' + flexDirection);
  }

  if (alignItems) {
    classNames.push('align-items-' + alignItems);
  }

  if (justifyContent) {
    classNames.push('justify-content-' + justifyContent);
  }

  return <div className={classNames.join(' ')}>{children}</div>;
};

export default Flex;
